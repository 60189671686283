import React from "react"
import SunshineTesting from "../templates/Sunshine/sunshineTesting"



export default function MediaRoom() {
  return (
    <div>
      <SunshineTesting />
    </div>
  )
}