import React, { useState, useEffect } from 'react';
import './sunshineButton.css';
import PropTypes from 'prop-types';
import ParticleEffectButton from 'react-particle-effect-button';

const SunshineButton = ({ background, buttonStyles, buttonOptions }) => {
  const [hidden, setHidden] = useState(false);
  const [animating, setAnimating] = useState(false);

  const onToggle = () => {
    if (animating) return;

    setHidden(!hidden);
    setAnimating(true);
  };

  const onAnimationComplete = () => {
    setAnimating(false);
  };

  useEffect(() => {
    let timer;
    if (hidden && !animating) {
      timer = setTimeout(() => {
        setHidden(false);
        setAnimating(false);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [hidden, animating]);

  return (
    <div
      className="buttons-div-container"
      style={{ background }}
    >
      <ParticleEffectButton
        hidden={hidden}
        onComplete={onAnimationComplete}
        {...buttonOptions}
      >
        <button
          className="particle-effect-button"
          style={{ ...buttonStyles }}
          onClick={onToggle}
        >
          Click me
        </button>
      </ParticleEffectButton>
    </div>
  );
};

SunshineButton.propTypes = {
  background: PropTypes.string.isRequired,
  buttonStyles: PropTypes.object.isRequired,
  buttonOptions: PropTypes.object.isRequired
};

export default SunshineButton;
