import React from 'react'
import Spline from '@splinetool/react-spline';
import "./sunshineTesting.css"
import sunShineLogo from "../../images/sunshinelogo1.png"
import SunshineButton from './sunshineButton';


export default function SunshineTesting() {
  return (
      <div>
        <div style={{ position: "relative" }}>
          <div className="media-image-text-box">
            <div className="media-image-background-text">
                <img src={sunShineLogo} alt='logo'/>
              <h1 className="media-image-topic">
                Sunshine
              </h1>
              <p>Bringing good things to life</p>
            </div>
          </div>
          <Spline scene="https://prod.spline.design/HSALLMoQi-TjPgtu/scene.splinecode" />
        </div>
        <SunshineButton />
      </div>
  )
}
